export default function () {
  const cart = document.querySelector('#ordersFirstStepTable');
  const certificate = document.querySelector('#ordersSecondStepTable');
  if(cart){
    const products = cart.querySelectorAll('.table__row');
    products.forEach((product) => {
      if (product.querySelector('input[type=text]')){
        product.querySelector('input').addEventListener('change', (e)=> {
          let data = [
              {
                id: e.target.dataset.id,
                quantity: e.target.value
              }
            ];
          updateCart(true, e, data, product);
        })
      }

      if (product.querySelector('.table-button--remove')) {
        product.querySelector('.table-button--remove').addEventListener('click', (e)=> {
          deleteFromCart(e.target.closest('.table__row'));
        })
      }
    })
  }
  if (certificate) {
    const checkAllCertificatesBtn = document.querySelector('#all');
    const products = certificate.querySelectorAll('.table__row');
    products.forEach((product) => {
      if (product.querySelector('input[type=checkbox]').dataset.id) {
        product.querySelector('input').addEventListener('change', (e)=> {
          let data = [
            {
              id: e.target.dataset.id,
              certificateRequest: product.querySelector('input').checked
            }
          ];
          updateCart(false, e, data);
        })
      }
    });
    checkAllCertificatesBtn.addEventListener('change', (e)=> {
      e.preventDefault();
      let data = [];
      products.forEach((product) => {
        if (product.querySelector('input[type=checkbox]').dataset.id){
          data.push(
              {
                id: product.querySelector('input[type=checkbox]').dataset.id,
                certificateRequest: checkAllCertificatesBtn.checked
              }
          );
        }
      });
      updateCart(false, '', data )
    })
  }

  const updateCart = (isFirstStep, inputEvnt, data, product) => {
    const lang = document.getElementsByTagName("html")[0].getAttribute("lang");
    let cookies = document.cookie.split(';').slice(1).join();
    const headers = new Headers();
    headers.append('Cookie', cookies);
    fetch(`/api/${lang}/cart/update`, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(data)
    }).then(res => res.json()).then(data =>{
      if(isFirstStep) {
        changePrices(product, inputEvnt.target.value, false, data)
      }
    })
        .catch(err => console.log(err))
  };

  const deleteFromCart = (product) => {
    const id = product.querySelector('input[type=text]').dataset.id;
    const quantity =  product.querySelector('input[type=text]').value;
    const lang = document.getElementsByTagName("html")[0].getAttribute("lang");
    let cookies = document.cookie.split(';').slice(1).join();
    const headers = new Headers();
    headers.append('Cookie', cookies);
    fetch(`/api/${lang}/cart/items/${id}`, {
      method: "DELETE",
      headers: headers,
    }).then(res => res.json()).then(data =>{
        changePrices(product, quantity, true, data);
      product.remove();
    })
        .catch(err => console.log(err))
  };

  const changePrices = (product, productQuantity, deleteItem, data) => {
    const itemPrice = parseFloat(product.querySelector('[data-item-price]').innerHTML.replace(",", ""));
    const itemNetto = product.querySelector('[data-netto-price]');
    const itemVat = product.querySelector('[data-vat]').dataset.vat;
    const itemBrutto = product.querySelector('[data-brutto-price]');
    const totalNetto = document.querySelector('[data-total-netto]');
    const totalBrutto = document.querySelector('[data-total-brutto]');
    const oldNetto = parseFloat(itemNetto.innerHTML.replace(",", ""));
    // const oldBrutto = parseFloat(itemBrutto.innerHTML.replace(",", ""));
    const discountField = document.querySelector('[data-customer-discount]')
    if(discountField) {
      const discount = parseFloat(discountField.innerHTML);
      const discountTotal = document.querySelector('[data-total-discount]');
      discountTotal.innerHTML = '- ' + data.discount;
    }
    document.querySelector('.cart-bar__count').textContent = data.itemsCount;

    const badge = document.querySelector('.cart-bar__badge');

    if(data.itemsCount>0){
      badge.classList.add('cart-bar__badge--active');
    }else{
      badge.classList.remove('cart-bar__info--active')
    }

    if(deleteItem){
      totalNetto.innerHTML = data.totalNetto;
      totalBrutto.innerHTML = data.totalBrutto;
      return
    }

    itemNetto.innerHTML = ((itemPrice * productQuantity)  ).toLocaleString("en-US", { minimumFractionDigits: 2 });

    if (itemVat) {
      itemBrutto.innerHTML = ((itemPrice * productQuantity) + ((itemPrice * productQuantity) * (itemVat / 100))).toLocaleString("en-US", { minimumFractionDigits: 2 });
    } else {
      itemBrutto.innerHTML = itemNetto;
    }
    updateDiff(totalNetto, itemNetto , oldNetto);
    // updateDiff(totalBrutto, itemBrutto , oldBrutto);
  };

  const updateDiff = (total, newPrice, oldPrice) => {
    return total.innerHTML = (parseFloat(total.innerHTML.replace(",", "")) + (parseFloat(newPrice.innerHTML.replace(",", "")) - oldPrice)).toLocaleString("en-US", { minimumFractionDigits: 2 });
  }
}